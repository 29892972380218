import React, { Component } from 'react';
import Registracia from '../../../ui/register/register';
import DB from '../../../util/db/db'

// core components
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import AlertDialog from '../../AlertDialog/AlertDialog'
//import SweetAlert from 'sweetalert2-react';
import '../../../../node_modules/@sweetalert2/theme-material-ui/material-ui.css'

class UserProfile extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      AlertDialogTitle: "",
      AlertDialogContent: "",
      AlertDialogOpen: false
    }

  }

  showAlert(title, message)
  {
    this.setState({
      AlertDialogTitle: title,
      AlertDialogContent: message,
      AlertDialogOpen: true
    });
  }

  hideAlert()
  {
    this.setState({ ...this.state, AlertDialogOpen: false });
  }

  updateHandler(params)
  {
    //console.log(params);
    //console.log(DB.token);
    
    let id = parseInt(this.props.user.id);
    let subject_id = parseInt(this.props.subject.id);

    let db = new DB();

    let data = {};
    data["user_realname"] = params["meno"] + " " + params["priezvisko"];
    data["user_email"] = params["user_email"];
    data["user_name"] = params["user_name"];
    data["user_password"] = params["user_password"];

    db.dbUpdate(data, "users", id, "Nebolo možné aktualizovať profil užívateľa");
    
    data = {};
    data["ico"] = params["ico"];

    db.dbUpdate(data, "subject", subject_id);

    //dbUpdate / or update contact data

    //dbUpdate / or update adress data
    
    db.exec().then(
      result => {this.showAlert("Info", "Profil bol aktualizovaný")},
      error => {this.showAlert("Chyba", error)}
    );


  }

  render(){

    const classes = {
      cardTitle: {
        ...dashboardStyle,
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
      }
    }

    const data = {...this.props.user, ...this.props.subject};

    let split = this.props.user.user_realname.split(" ");
    if(split.length === 1) data["priezvisko"] = split[0];
    else if(split.length === 2)
    {
      data["meno"] = split[0];
      data["priezvisko"] = split[1];
    }

    data["firma"] = this.props.subject["meno_priezvisko"];
    data["ulica"] = this.props.subject["address"]["ulica"];
    data["mesto"] = this.props.subject["address"]["mesto"];
    data["psc"] = this.props.subject["address"]["psc"];
    data["tel"] = this.props.subject["phone"]["kontaktne_udaje"];

    /*
    <SweetAlert
    show = {this.state.AlertDialogOpen}
    title = {this.state.AlertDialogTitle}
    text = {this.state.AlertDialogContent}
    onConfirm ={() => this.setState({ ...this.state, AlertDialogOpen: false })}
  />
  */

    return (
      <div style = {{padding: "5px"}}>
      <Grid container>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success">
              <h2 className={classes.cardTitle}>Profil užívateľa</h2>
            </CardHeader>
            <CardBody>
            <div>
              <Registracia data={data} submitHandler={(params) => this.updateHandler(params)}/>
            </div>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>

      <AlertDialog
        open = {this.state.AlertDialogOpen}
        title = {this.state.AlertDialogTitle}
        content = {this.state.AlertDialogContent}
        onConfirm ={this.hideAlert.bind(this)}
      />

      </div>
    )
  }
}

export default UserProfile;