//https://github.com/uolcz/ico-validator/blob/master/app/assets/javascripts/ico-validator.js

function validIco(ico) {

  if(ico.length === 1) return false;

var $newIco,
      $remainingDigits,
      i,
      j;

  if (ico.length === 8) {
    return ico.match(/^\d+$/) && this.lastNumberValid(ico);
  } else {
    $remainingDigits = 8 - ico.length;
    $newIco = ico;
    i = 0;
    j = $remainingDigits;
    while (i < j) {
      $newIco = '0' + $newIco;
      i++;
    }

    let result = $newIco.length === 8 && $newIco.match(/^\d+$/) && this.lastNumberValid($newIco);
    if(result == null) return false;
    return result;

    //return $newIco.length === 8 && $newIco.match(/^\d+$/) && this.lastNumberValid($newIco);
  }
}

function lastNumberValid(ico) {
  return parseInt(ico[ico.length - 1], 10) === this.calculateValidLastNumber(ico);
}

function calculateValidLastNumber(ico) {
  var sum = 0;
  for( var i = 0; i <= 6; i++ ) {
    sum += ico[i] * (8 - i);
  }
  var mod = sum % 11;
  if( mod === 0 || mod === 10 ) {
    return 1;
  }
  if( mod === 1 ) {
    return 0;
  }
  return 11 - mod;
}

const icoValidator = {
  validIco, lastNumberValid, calculateValidLastNumber
}

export default icoValidator