import React from 'react';

import TableDb from './../../table/tableDb';
import { LogicOperator } from '../../../util/db/SqlQueryBuilder';

//import Find from './find';
//import Form from './form';
//import CustomizedMenus from './../../table/menu'
import CustomizedMenus from './menu'
//import Form from './form';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Config from './../../config/config'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DB from './../../../util/db/db';
//-----------------------------------------------------

class CasesPayments extends TableDb {
    constructor(props) {
        super(props);

        this.title = "Prijaté platby";
        if(props.title !== undefined) this.state.title = props.title;

        this.dbTable = "case_platba";        

        this.state.columns = this.getColumns(props.ui);
        this.state.visibleColumns = this.getVisibleColumns(props.ui);

        this.state.isMobile = false;
        //this.state.typ = props.typ;
        //this.state.ui = props.ui;

        //this.state.subject_id = props.subject_id;
        //this.state.parent_id = props.parent_id;

        if(props.isMobile !== undefined)
        {
            if(props.isMobile) 
            {
                this.state.visibleColumns = ["actions", "meno_priezvisko"];
                this.state.isMobile = true;
            }
        }

        this.renamedColumns = {
          id: "id",
          datum: "dátum",
          datum_importu: "dátum importu",
          vs: "vs",
          iban: "iban",
          bic: "bic",
          typ: "typ",
          konst_symbol: "konšt.symbol",
          spec_symbol: "špec.symbol",
          suma: "suma"
        };
    }

    getColumns(ui)
    {
        return ["id", "datum", "datum_importu", "vs", "iban", "bic", "typ", "konst_symbol", "spec_symbol", "suma"];
    }

    getVisibleColumns(ui)
    {
        //id, case_id, datum, datum_importu, vs, iban, bic, typ, konst_symbol, spec_symbol, suma, poznamka, poznamka_internet_banking, user_id, do_not_report, reported, reported_date, reported_amount, insert_type, accounted, accounted_date, poradie_rozpocitania_platby, kurz, mena, stmt_id
        return ["datum", "datum_importu", "vs", "iban", "bic", "typ", "konst_symbol", "spec_symbol", "suma"];
    }

    componentWillReceiveProps (nextProps) {

        /*
        //iba ak subject_id, alebo parent_id bolo zmenene
        let setStateFlag = false;
        if(nextProps["subject_id"] !== undefined) setStateFlag = true;
        if(nextProps["parent_id"] !== undefined) setStateFlag = true;

        if(setStateFlag)
        {
            if(nextProps["subject_id"] === this.state.subject_id && nextProps["parent_id"] === this.state.parent_id) setStateFlag = false;
        }

        if(nextProps["ui"] !== this.state.ui) setStateFlag = true;
        if(nextProps["isMobile"] !== this.state.isMobile) setStateFlag = true;
        if(this.state.status !== TableDb.TABLE_STATUS.SHOWTABLE) setStateFlag = true;

        if(!setStateFlag) return;

        let newState = {...nextProps}
        newState["columns"] = this.getColumns(nextProps.ui);
        newState["status"] = TableDb.TABLE_STATUS.SHOWTABLE;
        newState["visibleColumns"] = this.getVisibleColumns(nextProps.ui);

        this.setState(
            newState,
            this.showTable
        );
        */
    }

 

    getDefaultQueryBuilder()
    {

        let sqlQueryBuilder = super.getDefaultQueryBuilder();
        let case_id = this.props.case_id;

        // sqlQueryBuilder.where("id in (select case_id from subject_case where archiv = false && subject_id = :subject_id)", LogicOperator.And);
        // sqlQueryBuilder.setParameter("subject_id", subject_id);

        sqlQueryBuilder.where("`case_id` = :case_id", LogicOperator.And);
        sqlQueryBuilder.setParameter("case_id", case_id);

        return sqlQueryBuilder;
    }



    setRow(row)
    {

        // let id = parseInt(row["status"]);
        // if( id in Config.enum_case_status ) {
        //     let str =  Config.enum_case_status[id];
        //     row["status"] = str.tr;

        //     return;
        // }


        //row["status"] = str.tr;
    }

    //add or edit
    renderForm()
    {
        let data = undefined;
        if(this.state.data == null);
        else data = this.state.data[this.index];

        //getIndex
        
        let status = TableDb.TABLE_STATUS.EDIT;
        if(data === undefined) 
        {
            data = {};
            status = TableDb.TABLE_STATUS.ADD;
        }

        return null;
        
        /*
        return (
            <Form
                handleBack={this.handleBack.bind(this)}
                data = {data}
                status = {status}
                parent_id = {this.state.parent_id}
                ui = {this.state.ui}
            />
        )
        */
        
    }

    handleTableDoubleClick(event, id, index){

    }  

    renderUiForm()
    {
        return this.renderForm();
    }

    renderUiTable()
    {
        return(
            <div>
                {this.renderTable()}
            </div>
        )
    }

}

export default CasesPayments;