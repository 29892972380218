import React from 'react';
import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

//dialog
import TextField from '@material-ui/core/TextField';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import _ from 'lodash';

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const NewLoan = (props) => {

    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [months, setMonths] = useState(1);
    const [dialogParams, setDialogParams] = useState({doba_uveru_v_mesiacoch: 1});
    //const [maxWidth, setMaxWidth] = React.useState(props.maxWidth);
    const [maxWidth, setMaxWidth] = React.useState('md');

    const handleCloseDialog = () => {
        let params;
        props.createNewLoan(params);
    };

    const validate = (event) =>{

    };



    const handleChangeDialogParams = (event) =>{

        let value = "";
        if(event.target.type === "checkbox") value =  event.target.checked;
        else value = event.target.value;

        dialogParams[event.target.name] = value;
        console.log(dialogParams);

        if(event.target.name === "doba_uveru_v_mesiacoch") setMonths(value);

        setDialogParams(dialogParams);
    }

    const handleDialogConfirm = () => {
        if(_.isEmpty(dialogParams)) props.createNewLoan();
        else props.createNewLoan(dialogParams);
    };

    return (
        <Dialog
          fullScreen = {props.fullScreen}
          fullWidth={true}
          maxWidth={maxWidth}
          open={open}
          onClose={handleCloseDialog}
          //style = {{zIndex: 50000}}
          PaperComponent={PaperComponent}
          PaperProps={{
            style: {
                backgroundColor: "#fff",
            },
         }}
          aria-labelledby="draggable-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        {
          props.fullScreen ? <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              
            </Typography>
            <Button autoFocus color="inherit" onClick={handleDialogConfirm}>
              Hľadaj
            </Button>
          </Toolbar>
        </AppBar> : null
        }
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Žiadosť o novú pôžičku</DialogTitle>
        <DialogContent>          

            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    <InputLabel id="demo-simple-select-label">Na koľko mesiacov peniaze potrebujete?</InputLabel>
                        <Select
                        style = {{display: 'flex'}}
                        labelId="demo-simple-select-label"
                        id="doba_uveru_v_mesiacoch"
                        name="doba_uveru_v_mesiacoch"
                        value={months}
                        onChange={handleChangeDialogParams}
                        >
                        <MenuItem value={1}>1 mesiac</MenuItem>
                        <MenuItem value={2}>2 mesiace</MenuItem>
                        <MenuItem value={3}>3 mesiace</MenuItem>
                        <MenuItem value={4}>4 mesiace</MenuItem>
                        <MenuItem value={5}>5 mesiacov</MenuItem>
                        <MenuItem value={6}>6 mesiacov</MenuItem>
                        <MenuItem value={7}>7 mesiacov</MenuItem>
                        <MenuItem value={8}>8 mesiacov</MenuItem>
                        <MenuItem value={9}>9 mesiacov</MenuItem>
                        </Select>
                </Grid>

                <Grid item sm={12} xs={12}>
                  <TextField
                    error = {dialogParams.istinaError}
                    name="istina"
                    value={dialogParams["istina"]}
                    onChange={handleChangeDialogParams}
                    onBlur={validate}
                    margin="dense"
                    id="istina"
                    label="Koľko potrebujete? (max 200€):"
                    type="text"
                    required
                    fullWidth
                    helperText = {dialogParams.istinaHelperText}
                  />
                </Grid>
            </Grid>

        </DialogContent>
        {
          props.fullScreen ? null : <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Zrušiť
          </Button>
          <Button onClick={handleDialogConfirm} color="primary">
            Vytvor žiadosť
          </Button>
        </DialogActions>
        }

      </Dialog>
      )
}

export default NewLoan;