import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewListIcon from '@material-ui/icons/ViewList';
import Home from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';
import { green } from '@material-ui/core/colors';

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Config from '../../config/config'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  colorPrimary : {
    backgroundColor: '#1B8AF1',
  },
  tooltip: {
    fontSize: 30
  }
}));



export default function MainMenu(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSetSidebarOpen = () => {
    props.onSetSidebarOpen(true);
  };

  const showUsers = () => {
    handleClose();
    props.showObject("Users");
  };

  const showUserProfile = () => {
    handleClose();
    props.showObject("UserProfile");
  };

  const showSubject = () => {
    handleClose();
    props.showObject("Subject");
  };

  const showDyhyPalluxOrders = () => {
    handleClose();
    props.showObject("DyhyPalluxOrders");
  };

  

  const showDefault = () => {
    handleClose();
    props.showObject("Default");
  };

  const showLoans = () => {
    handleClose();
    props.showObject("showLoans");
  };

  const handleLogOut = () => {
    handleClose();
    props.logoutHandler();
  };

  const buildSubMenu = () => {
    if(props.company === "datys")
    {

      console.log("buildSubMenu", props.subject_case.dlh_aktualne);


      let text = "moje úvery";
      if(Config.subject.typ === "PO") text = "zoznam pôžičiek pre investovanie";

      let items = [];
      items.push(             (
        <Tooltip className={classes.tooltip}  title={<h2 style={{ color: "white" }}>{text}</h2>} arrow>
          <Button variant="text" color='inherit' onClick={showLoans}>
            <ViewListIcon style={{ color: green[100] }} className={classes.menuButton}/>
          </Button>
        </Tooltip>
        ) );

        items.push(<div>{Config.user.user_realname}</div>);
        if(Config.subject.typ === "PO")  
        {
          let kredit = 0;
          kredit = parseFloat(props.subject_case.dlh_aktualne);
          if(kredit < 0) kredit = Math.abs(kredit);

          items.push(<div>&nbsp;kredit: {kredit} Eur</div>);
        }

        return items;

    }

    return null;
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.colorPrimary} position="static">
        <Toolbar>
          <IconButton edge="start" onClick={onSetSidebarOpen}  className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon/>
          </IconButton>

          <Tooltip title="návrat na hlavnú stránku" arrow>
            <Button variant="text" color='inherit' onClick={showDefault}>
              <Home style={{ color: green[100] }} className={classes.menuButton}/>
            </Button>
          </Tooltip>

          {
            buildSubMenu()
          }

          <div className={classes.root} />

          <IconButton
              onClick={handleClick}
              color="inherit"
            >

          <AccountCircle />
          </IconButton>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
            
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Odhlásiť" />
            </MenuItem>

          </Menu>

        </Toolbar>
      </AppBar>
    </div>
  );
}


/*

          <Button variant="text" color='inherit' onClick={showSubject}>
            <SupervisedUserCircleIcon className={classes.menuButton}/>
            Osoby
          </Button>
          <Button variant="text" color='inherit' onClick={showDyhyPalluxOrders}>
            <ListAltIcon className={classes.menuButton}/>Objednávky
          </Button>

*/

//<MenuItem onClick={showUserProfile}>Profil</MenuItem>
//<MenuItem onClick={showUsers}>Zoznam užívatelov</MenuItem>
