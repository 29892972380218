import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import MenuList from '@material-ui/core/MenuList';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

/*
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
*/

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(props.anchorref);

  const handleClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    props.menuClicked(menu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.menuClosed();
  };

  return (
    <div>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList open={Boolean(anchorEl)} id="menu-list-grow" >
          <MenuItem onClick={(event)=>handleClick(event, "add")}>
            <ListItemIcon>
              <AddCircleIcon style={{ color: green[500] }} fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">pridať</Typography>
          </MenuItem>
          <MenuItem onClick={(event)=>handleClick(event, "edit")}>
            <ListItemIcon>
              <EditIcon style={{ color: blue[300] }} fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">editovat</Typography>
          </MenuItem>
          <MenuItem onClick={(event)=>handleClick(event, "delete")}>
            <ListItemIcon>
              <DeleteForeverIcon style={{ color: red[500] }} fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">zmazať</Typography>
          </MenuItem>
          <MenuItem onClick={(event)=>handleClick(event, "find")}>
            <ListItemIcon>
              <SearchIcon style={{ color: blue[200] }} fontSize="default" />
            </ListItemIcon>
            <Typography variant="inherit">hľadať</Typography>
          </MenuItem>
        </MenuList>
      </StyledMenu>
    </div>
  );
}