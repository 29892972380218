import React from 'react';
import { useState } from "react";

//dialog
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import _ from 'lodash';

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

const Find = (props) => {

    const [open, setOpen] = useState(true);
    const [findDialogParams, setFindDialogParams] = useState({});

    const handleCloseDialog = () => {
        props.onConfirm();
    };

    const handleChangeDialogParams = (event) =>{
        findDialogParams[event.target.name] = event.target.value;
        //console.log(event.target.name, event.target.value);
    }

    const handleFindDialogConfirm = () => {
        if(_.isEmpty(findDialogParams)) props.onConfirm();
        else props.onConfirm(findDialogParams);
    };

    return (
        <Dialog
          fullScreen = {false}
          open={open}
          onClose={handleCloseDialog}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Hľadaj</DialogTitle>
        <DialogContent>
          <DialogContentText>
            zadajte parametre
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="meno_priezvisko"
            name="meno_priezvisko"
            label="Meno / obchodný názov"
            type="meno_priezvisko"
            fullWidth
            onChange={handleChangeDialogParams}
            value= {findDialogParams["meno_priezvisko"]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Zrušiť
          </Button>
          <Button onClick={handleFindDialogConfirm} color="primary">
            Hľadaj
          </Button>
        </DialogActions>
      </Dialog>
      )
}

export default Find;