import React, { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import Input from "@material-ui/core/Input";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

export default function LoansForm(props) {
  
  //const { control, errors, handleSubmit, setValue} = useForm();
  const [data, setData] = React.useState(props.data);

  const getValue = (key) =>
  {
    let value = "";
    if(data !== undefined) value = data[key];
    if(value === undefined) value = "";

    return value;
  } 

  const getError = (key) =>
  {
    let result = {};

    if(key === "meno_priezvisko")
    {

      let value = getValue(key);

      if(value === "")
      {
        result.error = true;
        result.error_message = "povinná položka";
      }
    }

    return result;
  }

  const handleChange = (event) => {

    //send data to parent
    let data = {};
    data[event.target.name] = event.target.value;
    props.collectData(props.table, data);

    setData(data);

  };

  const onSubmit = (data) => {
    //console.log(errors);alert(errors);
    props.collectData(props.table, data);
    return;
  };

  //componentDidMount 
  /*
  useEffect(() => {
    // Your code here
  }, []);
  */

 useEffect(() => {
  window.addEventListener('mousemove', () => {});
  
  // returned function will be called on component unmount 
  return () => {

    //handleSubmit(onSubmit)();
    window.removeEventListener('mousemove', () => {})
  }
}, [])


  //let btn = <Button onClick = {handleSubmit(onSubmit)} style = {{color: 'white', backgroundColor: "green", float: "right"}} variant="outlined" startIcon={<SaveIcon />}>Ulož</Button>

/*

      <Controller
         render={({ onChange, value }) => (
          <Input
            onChange ={value => handleChange(value)}
            value={value}
            name="meno_priezvisko"
          />
        )}
        name="meno_priezvisko"
        control={control}
        rules={{ required: true }}
        defaultValue={props.data.meno_priezvisko}
      />

      {errors.meno_priezvisko && "First name is required"}

*/

  return (
    <form>

      <Grid container spacing={3}>

        <Grid item xs={12} sm={6}>
          <TextField
              name="meno_priezvisko"
              onChange = {value => handleChange(value)}
              error = {getError("meno_priezvisko").error}
              id="standard-error-helper-text"
              label="Obch. názov"
              variant="outlined"
              defaultValue={props.data.meno_priezvisko}
              helperText={getError("meno_priezvisko").error_message}
              fullWidth
            />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel id="demo-simple-select-label">Právna forma</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.data.typ}
            onChange={handleChange}
            name = "typ"
          >
            <MenuItem value={"PO"}>právnická osoba</MenuItem>
            <MenuItem value={"FO - podnikatel"}>SZČO</MenuItem>
            <MenuItem value={"FO"}>fyzická osoba</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="celk_vyska_uveru"
            onChange = {value => handleChange(value)}
            error = {getError("celk_vyska_uveru").error}
            id="standard-error-helper-text"
            label="Celková výška úveru"
            defaultValue={props.data.celk_vyska_uveru}
            helperText={getError("celk_vyska_uveru").error_message}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="dlh_aktualne"
            onChange = {value => handleChange(value)}
            error = {getError("dlh_aktualne").error}
            id="standard-error-helper-text"
            label="Aktuálny dlh"
            defaultValue={props.data.dlh_aktualne}
            helperText={getError("dlh_aktualne").error_message}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="prijate_platby"
            onChange = {value => handleChange(value)}
            error = {getError("prijate_platby").error}
            id="standard-error-helper-text"
            label="Suma prijatých platieb"
            defaultValue={parseFloat(props.data.prijate_platby)}
            helperText={getError("prijate_platby").error_message}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="cislo_uctu"
            onChange = {value => handleChange(value)}
            error = {getError("cislo_uctu").error}
            id="standard-error-helper-text"
            label="Číslo bankového účtu"
            defaultValue={props.data.cislo_uctu}
            helperText={getError("cislo_uctu").error_message}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="ico"
            onChange = {value => handleChange(value)}
            error = {getError("ico").error}
            id="standard-error-helper-text"
            label="IČO"
            defaultValue={props.data.ico}
            helperText={getError("ico").error_message}
            fullWidth
          />
        </Grid>

        

        <Grid item xs={12} sm={6}>
          <TextField
            name="dic"
            onChange = {value => handleChange(value)}
            error = {getError("dic").error}
            id="standard-error-helper-text"
            label="DIČ"
            defaultValue={props.data.dic}
            helperText={getError("dic").error_message}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="ic_dph"
            onChange = {value => handleChange(value)}
            error = {getError("ic_dph").error}
            id="standard-error-helper-text"
            label="IČ DPH"
            defaultValue={props.data.ic_dph}
            helperText={getError("ic_dph").error_message}
            fullWidth
          />
        </Grid>


      </Grid>




    </form>
  );
}