import React from 'react';

import TableDb from './../../table/tableDb';
import { LogicOperator } from '../../../util/db/SqlQueryBuilder';

//import Find from './find';
//import Form from './form';
//import CustomizedMenus from './../../table/menu'

import NewLoan from './new_loan'
import CustomizedMenus from './menu'
import Form from './form';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Config from './../../config/config'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DB from './../../../util/db/db';
import moment from 'moment'

import {connect} from 'react-redux'
import * as actionTypes from '../../../store/actions'
import { getSectionHasLiquidHeight } from '@fullcalendar/react';
//-----------------------------------------------------

class Loans extends TableDb {
    constructor(props) {
        super(props);

        this.title = "Zoznam pôžičiek";
        if(props.title !== undefined) this.state.title = props.title;

        this.dbTable = "case";        

        this.state.columns = this.getColumns(props.ui);
        this.state.visibleColumns = this.getVisibleColumns(props.ui);

        this.state.isMobile = false;
        this.state.showNewLoanDialog = false;

        this.state.actions = ["view"];
        //this.state.typ = props.typ;
        //this.state.ui = props.ui;

        //this.state.subject_id = props.subject_id;
        //this.state.parent_id = props.parent_id;

        if(props.isMobile !== undefined)
        {
            if(props.isMobile) 
            {
                this.state.visibleColumns = ["actions", "meno_priezvisko"];
                this.state.isMobile = true;
            }
        }

        this.renamedColumns = {
          id: "id",
          status: "stav",
          rating: "rating",
          actions: "",
          meno_priezvisko: "meno",
          celk_vyska_uveru: "výška úveru",
          typ_uveru: "úver",
          doba_uveru_v_mesiacoch: "doba",
          datum_predc_splatnosti: "dátum predč.splatnosti",
          splatnost: "splatnosť",
          cislo_zmluvy: "číslo zmluvy",
          vs: "var. symbol",
          datum_importu: "vytvorené",
          archiv: "archív"
        };
    }

    getColumns(ui)
    {
        if(this.props.typ === "PO")
        {
            return ["id", "actions", "status", "rating", "mesto", "celk_vyska_uveru", "typ_uveru", "doba_uveru_v_mesiacoch", "splatnost", "cislo_zmluvy", "vs", "datum_importu", "datum_predc_splatnosti", "archiv"];
        }

        return ["id", "actions", "status", "rating", "meno_priezvisko", "celk_vyska_uveru", "typ_uveru", "doba_uveru_v_mesiacoch", "splatnost", "cislo_zmluvy", "vs", "datum_importu", "datum_predc_splatnosti", "archiv"];
    }

    getVisibleColumns(ui)
    {

        if(this.props.typ === "PO")
        {
            return ["id", "actions", "status", "rating", "mesto", "celk_vyska_uveru", "typ_uveru", "doba_uveru_v_mesiacoch", "splatnost", "cislo_zmluvy", "vs", "datum_importu", "datum_predc_splatnosti", "archiv"];
        }

        return ["id", "actions", "status", "rating", "meno_priezvisko", "celk_vyska_uveru", "typ_uveru", "doba_uveru_v_mesiacoch", "splatnost", "cislo_zmluvy", "vs", "datum_importu", "archiv"];
    }

    componentWillReceiveProps (nextProps) {

        /*
        //iba ak subject_id, alebo parent_id bolo zmenene
        let setStateFlag = false;
        if(nextProps["subject_id"] !== undefined) setStateFlag = true;
        if(nextProps["parent_id"] !== undefined) setStateFlag = true;

        if(setStateFlag)
        {
            if(nextProps["subject_id"] === this.state.subject_id && nextProps["parent_id"] === this.state.parent_id) setStateFlag = false;
        }

        if(nextProps["ui"] !== this.state.ui) setStateFlag = true;
        if(nextProps["isMobile"] !== this.state.isMobile) setStateFlag = true;
        if(this.state.status !== TableDb.TABLE_STATUS.SHOWTABLE) setStateFlag = true;

        if(!setStateFlag) return;

        let newState = {...nextProps}
        newState["columns"] = this.getColumns(nextProps.ui);
        newState["status"] = TableDb.TABLE_STATUS.SHOWTABLE;
        newState["visibleColumns"] = this.getVisibleColumns(nextProps.ui);

        this.setState(
            newState,
            this.showTable
        );
        */
    }

    getTableName(withView = false)
    {
        if(withView) return "view_case_business_loans";
        return this.dbTable;
    }

    getDefaultQueryBuilder()
    {

        let sqlQueryBuilder = super.getDefaultQueryBuilder();
        let subject_id = Config.user.subject_id;

        //todo role, object manager id

        if(this.props.typ === "FO")
        {
            //sqlQueryBuilder.where("id in (select case_id from subject_case where archiv = false && subject_id = :subject_id && case_subject_role = 'klient')", LogicOperator.And);
            sqlQueryBuilder.where("subject_id = :subject_id", LogicOperator.And);
            sqlQueryBuilder.setParameter("subject_id", subject_id);

            //sqlQueryBuilder.where("id in (select case_id from subject_case where archiv = false && subject_id = :subject_id)", LogicOperator.And);
        }
        else
        {
            //sqlQueryBuilder.where("id in (select case_id from subject_case where archiv = false && case_subject_role = 'klient')", LogicOperator.And);

            sqlQueryBuilder.where("id not in (select case_id from subject_case where archiv = false && case_subject_role = 'investor')", LogicOperator.And);

            sqlQueryBuilder.where("(`case_subject_role` = 'klient' OR `case_subject_role` IS NULL)", LogicOperator.And);
            sqlQueryBuilder.where("object_manager_id = :object_manager_id", LogicOperator.And);
            sqlQueryBuilder.setParameter("object_manager_id", 5);

            sqlQueryBuilder.where("status = :status", LogicOperator.And);
            sqlQueryBuilder.setParameter("status", 3);
        }

        sqlQueryBuilder.where("`archiv` = :archiv", LogicOperator.And);
        sqlQueryBuilder.setParameter("archiv", false);

        console.log(sqlQueryBuilder.getSql());

        return sqlQueryBuilder;
    }

    buildFindSqlQuery(findDialogParams)
    {

        let sqlQueryBuilder = super.buildFindSqlQuery(findDialogParams);
        if(sqlQueryBuilder == null) return;

        /*
        if("meno_priezvisko" in findDialogParams)
        {
          if(typeof findDialogParams.meno_priezvisko === "string")
          {
            sqlQueryBuilder.where("`meno_priezvisko` LIKE :meno_priezvisko", LogicOperator.And);
            sqlQueryBuilder.setParameter("meno_priezvisko", "%" + findDialogParams["meno_priezvisko"] + "%");
          }
        }

        if("ico" in findDialogParams)
        {
          if(typeof findDialogParams.ico === "string")
          {
            sqlQueryBuilder.where("`ico` LIKE :ico", LogicOperator.And);
            sqlQueryBuilder.setParameter("ico", "%" + findDialogParams["ico"] + "%");
          }
        }
        */

        this.showTable(sqlQueryBuilder);
    }

    setRow(row)
    {

        if(row["prijate_platby"] === null) row["prijate_platby"] = 0;
        if(isNaN(row["prijate_platby"])) row["prijate_platby"] = 0;

        if(this.props.typ === "PO")
        {
            row["_meno_priezvisko"] = row["meno_priezvisko"];
            row["meno_priezvisko"] = "*******";

            row["_cislo_uctu"] = row["cislo_uctu"];
            row["cislo_uctu"] = "*******";
        }

        let id = parseInt(row["status"]);
        if( id in Config.enum_case_status ) {
            let str =  Config.enum_case_status[id];
            row["_status"] = row["status"];
            row["status"] = str.tr;

            return;
        }


        //row["status"] = str.tr;
    }

    setInvestment()
    {

        if(this.selectedRows.length !== 1)
        {
            this.showAlertDialog("Investovanie", "Označte úver do ktorého chcete investovať");
            return;
        }

        let kredit = 0;
        kredit = parseFloat(this.props.subject_case.dlh_aktualne);
        if(kredit < 0) kredit = Math.abs(kredit);

        
        let index= this.selectedRows[0];
        let row = this.getRowData(index);
        let investovanie = row.celk_vyska_uveru;
        let case_id = row.id;

        let status = row._status;

        if(kredit <  investovanie) {
            
            this.showAlertDialog("Nedostatočný kredit", "Váš kredit je vo výške " + kredit + " eur");
            return;
        }


        //insert subject_case
        //id, subject_id, user_id, case_id, created, case_subject_role, share, card_nr, archiv, archiv_user_id, archiv_datetime

        let db = new DB();
        db.dbStartTransaction();

        let dbSubjectCaseData = {};
        let foreign_keys = {};
        //foreign_keys.subject_id = parseInt(this.props.subject_case.subject_id);
        //foreign_keys.case_id = row.id;

        //subject case
        dbSubjectCaseData["created"] = moment(new Date()).format("YYYY-MM-DD");
        dbSubjectCaseData["case_subject_role"] = "investor";
        dbSubjectCaseData["archiv"] = false;
        dbSubjectCaseData["subject_id"] = parseInt(this.props.subject_case.subject_id);
        dbSubjectCaseData["case_id"] = case_id;

        //console.log(dbSubjectCaseData, foreign_keys);

        db.dbInsert(dbSubjectCaseData, "subject_case", foreign_keys, "nepodarilo sa priradit investora k uveru");

        //TODO update status
        //vlozime do historie - id, case_id, datum, status, note, aktualny, user_id

        //clear all to false
        let statusData = {};
        statusData["aktualny"] = 0;
        db.dbUpdate(statusData, 'case_status', "case_id = " + case_id);

        //PRIRADENY_INVESTOR - id - 9
        statusData = {};
        statusData["case_id"] = case_id;
        statusData["datum"] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        statusData["status"] = 9;
        statusData["aktualny"] = true;

        db.dbInsert(statusData, "case_status", foreign_keys, "nepodarilo ulozit status do historie (1)");

        //aktualizovat status v case
        let caseData = {};
        caseData["status"] = 9;

        db.dbUpdate(caseData, 'case', case_id);

        //vlozit platbu
        let investorCaseId = parseInt(this.props.subject_case.id);

        let casePayment = {};
        casePayment["case_id"] = investorCaseId;//tu ma byt case id investora!!!
        casePayment["datum"] = moment(new Date()).format("YYYY-MM-DD");
        casePayment["datum_importu"] = casePayment["datum"];
        casePayment["iban"] = this.getRowData(index, "cislo_uctu");
        casePayment["typ"] = "presun";
        casePayment["suma"] = 0 - investovanie;
        casePayment["poznamka"] = "investicia ";
        casePayment["insert_type"] = "sherlook.light";
        casePayment["move_case_id"] = case_id;

        db.dbInsert(casePayment, "case_platba", foreign_keys, "nepodarilo ulozit plabu");

        //rozpocitanie platieb
        //id, case_id, datum, suma, zapocitane, zapocitane_na, popis, payment_id, sign, invoice_id

        //aktualizovat dlh_aktualne investora!!
        caseData = {};
        caseData["dlh_aktualne"] =  0 - (kredit - investovanie);
        caseData["navysenie_aktualne"] = caseData["dlh_aktualne"];
        db.dbUpdate(caseData, 'case', investorCaseId);

        //vlozit ulohu do kalendara - timeline
        let timelineData = {};
        timelineData["object_manager_id"] = row.object_manager_id;
        timelineData["case_id"] = case_id;
        timelineData["title"] = "investovovanie: " + this.props.subject.meno_priezvisko;
        timelineData["description"] = "investor: " + this.props.subject.meno_priezvisko + " bol priradený k úveru: " +  this.getRowData(index, "meno_priezvisko") + "\nuloha: previest sumu " + investovanie + " Eur na ucet klienta";
        timelineData["date"] = moment(new Date()).format("YYYY-MM-DD");
        timelineData["all_day_task"] = 1;
        timelineData["akcia"] = "payment";
        timelineData["status"] = "scheduled";
        timelineData["status_asked"] = "done-valid";
        timelineData["topic"] = 2;
        timelineData["created"] = moment(new Date()).format("YYYY-MM-DD");
        timelineData["subject_id"] = row.subject_id;
        timelineData["subject_specific"] = 0;
        timelineData["warn_before"] = 0;

        db.dbInsert(timelineData, "timeline", foreign_keys, "nepodarilo vytvorit ulohu v kalendari!");

        db.dbCommitTransaction();

        db.exec().then(
            result => {

            //update kontaktne udaje
            console.log(result);

            let params = {sum: investovanie};
            this.props.lowerCredit(params);

            this.setState(
                {"timestamp":  Date.now()}
                , this.showTable);
    
                this.showAlertDialog("Super", "investícia bola priradená", "ok");
            
            },
            error => {
                console.log(error);
                this.showAlertDialog("Chyba", error);
            }
        );



        return;
    }

    menuClicked(menu)
    {

        //this.menuClosed();
        //return;
        if(menu === "addInvestment")
        {
            this.menuClosed();
            this.setInvestment();
            return;
        }

        //process default menu
        if(menu === "delete")
        {
            //alert(this.id + "-" + Config.subject.subject_id);

            console.log(this.state.data[this.index]);

            this.menuClosed();
            this.showAlertDialog("mazanie", "Nie je možné zmazať úver");
            return;

            if(this.id == Config.subject.subject_id)
            {
                this.menuClosed();
                this.showAlertDialog("mazanie", "Nie je možné zmazať hlavnú firmu");
                return;
            }

            if(this.selectedRows.length === 0)
            {
                this.menuClosed();
                this.showAlertDialog("Mazanie", "Nie sú označené žiadne riadky");
                return;
            }

            var r = window.confirm("Naozaj chcete zmazať?");
            if (r === false) return;

            let db = new DB();
            db.dbStartTransaction();
            
            //SubjectCaseAndAssigned - odstranit z subject_assigned, a zmazat subject
            if(this.state.ui === "SubjectCaseAndAssigned")
            {
                let conditions = {"subject_assigned_id": this.id};
                db.dbDelete("subject_assigned", conditions);
            }

            //SubjectCase - odstranit z subject_case, a zmazat subject
            if(this.state.ui === "SubjectCase")
            {
                let conditions = {"subject_id": this.id};
                db.dbDelete("subject_case", conditions);
            }
            
            //default - odstranit z subject
            let conditions = {"id": this.id};
            db.dbDelete(this.getTableName(false), conditions);
            db.dbCommitTransaction();
    
            let index = this.index;

            db.exec().then(
              result => {
                this.deleteRow(index);
              },
              error => {
                console.log(error);
                this.showAlertDialog("Chyba", error);
              }
            );

            return;

        }

        if(menu === "add")
        {
            this.menuClosed();

            //load from db, test if new loan is possible
            let sqlQueryBuilder = this.getDefaultQueryBuilder();
            sqlQueryBuilder.replaceSelect("count(id)", "count");

            this.db.dbSelect(sqlQueryBuilder, true).then(
                result => {

                    console.log(result);

                    //just test
                    this.setState({"showNewLoanDialog": true});
                    return;

                    if(result.length > 0)
                    {
                        if(parseInt(result[0]["count"]) > 0)
                        {
                            this.showAlertDialog("Chyba", "Nie je možné požiadať o novú pôžičku");

                            return;
                        }
                        
                    }

           
                },
                error => {
                    //alert(error);
                    this.showAlertDialog("Chyba", error);
                }
                );

            //this.setState({"showNewLoanDialog": true});
            return;
        }

        let processed = super.menuClicked(menu);
        if(processed) return;
        
        //alert(menu);
    }

    renderFindDialog()
    {
        //detect mobile
        let isFullScreen = false;
        if(this.props.isMobile !== undefined)
        {
            if(this.props.isMobile)
            {
                isFullScreen = true;
            }
        }

        //isFullScreen = true;//test

        return null;

        /*
        return (
            <Find
                fullScreen={isFullScreen}
                onConfirm ={this.buildFindSqlQuery.bind(this)}
            />
        )
        */
        
    }

    createNewLoan(params)
    {
        console.log(params);
        

        this.setState({"showNewLoanDialog": false});
    }

    rendeNewLoanDialog()
    {
        //detect mobile
        let isFullScreen = false;
        if(this.props.isMobile !== undefined)
        {
            if(this.props.isMobile)
            {
                isFullScreen = true;
            }
        }

        //isFullScreen = true;//test
        

        return (
            <NewLoan
                fullScreen={isFullScreen}
                createNewLoan ={this.createNewLoan.bind(this)}
            />
        )
  
        
    }

    saveForm()
    {
        //? move here from form?
    }

    renderAddDialog()
    {
        return this.renderFindDialog();
    }

    //add or edit
    renderForm()
    {
        let data = undefined;
        if(this.state.data == null);
        else data = this.state.data[this.index];

        //getIndex
        
        let status = TableDb.TABLE_STATUS.EDIT;
        if(data === undefined) 
        {
            data = {};
            status = TableDb.TABLE_STATUS.ADD;
        }
        
        return (
            <Form
                handleBack={this.handleBack.bind(this)}
                data = {data}
                status = {status}
                parent_id = {this.state.parent_id}
                ui = {this.state.ui}
            />
        )
        
    }

    //add or edit
    renderUiForm()
    {
        return this.renderForm();
    }

    //show table status
    renderUiTable()
    {

        let modalDialog = null;
        if(this.state.showNewLoanDialog ) modalDialog = this.rendeNewLoanDialog();

        let addButton = ( <Tooltip title={<h2 style={{ color: "white" }}>nová pôžička</h2>} arrow>
        <Button onClick={() => this.menuClicked("add")} style = {{color: 'white', backgroundColor: "orange", float: "right"}} variant="outlined" startIcon={<AddIcon />}>
            nová pôžička
        </Button>
    </Tooltip>);

        //investor
        if(Config.subject.typ === "PO")
        {
            addButton = ( <Tooltip title={<h2 style={{ color: "white" }}>nová investicia</h2>} arrow>
            <Button onClick={() => this.menuClicked("addInvestment")} style = {{color: 'white', backgroundColor: "orange", float: "right"}} variant="outlined" startIcon={<AddIcon />}>
                nová investicia
            </Button>
        </Tooltip>);
        }

        return(
            <>
            {modalDialog}
            <Card>
                <CardHeader color="success">

                    <Grid container justify="space-between">  

                        <Typography inline variant="body1" align="left">

                            <Tooltip title={<h2 style={{ color: "white" }}>zobraz menu</h2>} arrow>
                                <Button
                                    ref={this.anchorMenuRef}
                                    aria-controls={this.state.openMenu ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleToggleMenu.bind(this)}
                                    startIcon={<MoreVertIcon />}
                                >
                                {this.state.title}
                            </Button>
                            </Tooltip>
                            {this.renderCancelFilterIcon()}

                            <IconButton onClick={() => this.menuClicked("find")} aria-label="search" color="inherit">
                                <SearchIcon />
                            </IconButton>
                            <span>{Config.subject.typ === "PO" ? "zoznam úverov pre investovanie" : "moje pôžičky"}</span>
                        </Typography>

                        <Typography inline variant="body1" align="right">
                            {addButton}
                        </Typography>

                    </Grid>

                    </CardHeader>
            <CardBody>
            <div>
                {this.renderTable()}
            </div>
            </CardBody>
        </Card>
        </>
        )
    }

    renderMenu()
    {
        return (
            <CustomizedMenus
            anchorref={this.anchorMenuRef.current}
            menuClicked = {this.menuClicked.bind(this)}
            menuClosed = {this.menuClosed.bind(this)}
            />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
      lowerCredit: (params) => dispatch ({
        type: actionTypes.LOWER_CREDIT,
        payload: {
            sum: params.sum
        }
      })
    }
  }

const mapStateToProps = state => {
    //console.log("Loans state", state);
      return{
        user: state.user,
        subject: state.subject,
        subject_case: state.subject_case,
        permissions: state.permissions,
        showObject: state.showObject
      }
    };
  

//export default Loans;
export default connect(mapStateToProps, mapDispatchToProps)(Loans);