import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import logo from './../resources/najcredit.svg';
import Grid from '@material-ui/core/Grid';
import icoValidator from '../../util/icoValidator';
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const useStyles = theme => ({
    root: {
      backgroundColor: "transparent",
      backgroundImage: "linear-gradient(135deg, #1B8AF1, #d8deeb)"    //backgroundImage: "linear-gradient(to right, #1B8AF1, #d8deeb)"
     },
  });

const initialState = {
    meno: "",
    priezvisko: "",
    firma: "",
    ico: "",
    ic_dph: "",
    ulica: "",
    mesto: "",
    psc: "",
    user_email: "",
    tel: "",
    showProgress: false,
    registerMessage: "",
    registered: true,
    role: "investor"
};

class RegistraciaInvestor extends Component {
  
  constructor(props){
    super(props)

    this.submitHandler = props.submitHandler

    this.state = {
      ...initialState,
      modal: props.modal,
    }

    let keys = Object.keys(initialState);
    for (var index in keys) {
        let key = keys[index];
        this.state[key + "Error"] = false;
        this.state[key + "HelperText"] = "";
    }

    if(props.data !== undefined)
    {
      let keys = Object.keys(initialState);

      for (var index in keys) {
        let key = keys[index];
        if (props.data[key] === undefined) continue;

        this.state[key] = props.data[key];
      }
 
    }
  }
 
  validateField(event, key, value)
  {

    if(event !==undefined)
    {
      key = event.target.name;
      value = event.target.value;
    }

    //suhlas_obch_podmienky
    //suhlas_spracovanie_udajov

    if(key === "meno")
    {
      if (!value){
        return "Nezadané meno";
      }
    }

    if(key === "priezvisko")
    {
      if (!value){
        return "Nezadané priezvisko";
      }
    }

    /*
    if(key === "firma")
    {
      if (!value){
        return "Nezadaný obch. názov";
      }
    }
    */

    if(key === "ulica")
    {
      if (!value){
        return "Nezadaná ulica";
      }
    }

    if(key === "mesto")
    {
      if (!value){
        return "Nezadané mesto";
      }
    }

    if(key === "psc")
    {
      if (!value){
        return "Nezadané PSČ";
      }
      else if(this.validatePSC(value)===false){
        return "Neplatný formát psč";
      }
    }

    if(key === "tel")
    {
      if (!value){
        return "Nezadaný telefón";
      }
      else if(this.validateTel(value)===false){
        return "Nesprávny formát čísla";
      }
    }

    if(key === "user_email")
    {
      if (!value){
        return "Nezadaný email";
      }
      else if(this.validateEmail(value)===false){
        return "Email je v zlom formáte";
      }
    }

    if(event === undefined)
    {

      if(key === "firma")
      {
        if(value === "")
        {
          let value1 = this.state["ico"];
          let value2 = this.state["ic_dph"];
          
          if(value1 !== "" || value2 !== "") return "Nie je zadaný názov firmy";
        }
        else
        {
          //let value1 = this.state["ico"];
          //if(value1 !== "") return "Nie je zadané IČO";
        }
      }

      if(key === "ico")
      {
        let firma = this.state["firma"];
        if(firma !== "") 
        {
          if(value === "") return "IČO nie je zadané";
          if (icoValidator.validIco(value)===false) return "Nesprávny formát IČO";
        }
      }
    }

    if(key === "ico")
    {
      if(value !== "") if (icoValidator.validIco(value)===false) return "Nesprávny formát IČO";

      if(value === "") 
      {
        let firma = this.state["firma"];
        if(firma !== "") 
        {
          return "IČO nie je zadané";
        }
        
      }
    }

    if(key === "ic_dph")
    {
      if(value !== "") if (this.validateteDic(value)===false) return "Nesprávny formát DIČ";
    }

    return "";
  }

  register()
  {
    axios('/backend/register.php',
    {
      method: 'POST',
      mode: 'no-cors',
      data:{
        ...this.state,
        company: "datys",
        role: "investor"
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        //'Content-Type': 'application/json',
        "Content-Type": "text/html;charset=UTF-8"
      },
      withCredentials: false,
      credentials: 'same-origin',
    })
    .then(response => {  

      if(!response.data.sucess)
      {
        throw response.data.message;
      }

      let newState = {...initialState}
      let keys = Object.keys(initialState);
      for (var index in keys) {
          let key = keys[index];
          newState[key + "Error"] = false;
          newState[key + "HelperText"] = "";
      }

      //newState = {};//just test
      newState["showProgress"] = false;
      newState["registerMessage"] = "Vaša registrácia bola úspešne vykonaná. Zaslali sme Vám email s dalšími inštrukciami."

      this.setState(newState);

      //if(!this.state.rememberMe) params.token = "";
      //this.props.loginHandler(params);
    })
    .catch(error => {
      const message = error + "";

      this.setState(
        {
          ...this.state,
          showProgress: false,
          registerMessage: message
        }
      );
    }); 

  }

  validate = (event) => {

    let key = undefined;
    let value = undefined;

    if(event !==undefined)
    {
      key = event.target.name;
      value = event.target.value;

      let result = this.validateField(event);

      let newState = {};
      newState[key + "Error"] = false;
      newState[key + "HelperText"] = "";
      
      if(result !== "")
      {
        newState[key + "Error"] = true;
        newState[key + "HelperText"] = result;
      }

      this.setState(newState);

      return;
    }

    let isValid = true;
    
    let keys = Object.keys(this.state);
    let newState = {...this.state};

    for (var index in keys) {
      let key = keys[index];
      newState[key + "Error"] = false;
      newState[key + "HelperText"] = "";

      let result = this.validateField(undefined, key, this.state[key]);

      if(result !== "")
      {
        newState[key + "Error"] = true;
        newState[key + "HelperText"] = result;

        isValid = false;
      }
      
    }

    this.setState(newState);

    return isValid;

  }

  validateTel = tel => {
    const reTelefon = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    let isValid = reTelefon.test(tel);
    return isValid;
  }
  
  validatePSC = psc => {
    const rePSC = /^\d{5}$/;
    return rePSC.test(psc);
  }
  
  validateteDic = dic => {
    const reDic = /^[A-Z]{2}\d{10}$/;
    return reDic.test(dic);
  }

  validateEmail = email => {
    const reEmail = /\S+@\S+\.\S+/;
    return reEmail.test(email);
  }
    
  handleSubmit = event => {
    event.preventDefault();
    let isValid = this.validate();
    //let isValid = true;//for testing
    
    if (isValid) {

      //console.log('everything ok');

      let data = {};
      let keys = Object.keys(initialState);
      for (var index in keys) {
        let key = keys[index];
        data[key] = this.state[key];
      }

      //showProgress: false
      this.setState({"showProgress": true}, this.register);

      if(this.submitHandler) 
      {
        this.submitHandler(data);
      }
      else
      {
        //ajax call to register
        //post data
      }
    }
    else
    {
      //alert("chyba");
      console.log("error handleSubmit");
    }
  };

  handleChange = event => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  };

  render(){

    /*
    const classes = {
      root: {
        flexGrow: 1,
      },
      bcg: {
        backgroundColor: "transparent",
        backgroundImage: "linear-gradient(135deg, #1B8AF1, #d8deeb)"    //backgroundImage: "linear-gradient(to right, #1B8AF1, #d8deeb)"
      },
    }
    */
    
   const { classes } = this.props;

   if(this.state.registerMessage !=="")
   {
     return(
       <div>
       <Dialog open={true}  aria-labelledby="form-dialog-title"
         BackdropProps={{
           classes: {
             root: classes.root
           }
         }}
       >
       <DialogTitle id="customized-dialog-title">
           <Grid container alignItems="center" spacing={0}>
               <Grid item xs={12} sm={6}><img src={logo} className="App-logo" alt="logo" /></Grid>
           </Grid>
       </DialogTitle>

         <DialogContent>

           <div>{this.state.registerMessage}</div>
           <br></br>

         </DialogContent>

         <DialogActions>
         <Button onClick={()=>{this.setState({registerMessage: "", showProgress: false})}} color="primary">
           Nová registrácia
         </Button>
       </DialogActions>

       </Dialog>
     </div>
     )
   }

   if(this.state.showProgress)
   {
     return(
       <div>
       <Dialog open={true}  aria-labelledby="form-dialog-title"
         BackdropProps={{
           classes: {
             root: classes.root
           }
         }}
       >
       <DialogTitle id="customized-dialog-title">
           <Grid container alignItems="center" spacing={0}>
               <Grid item xs={12} sm={6}><img src={logo} className="App-logo" alt="logo" /></Grid>
           </Grid>
       </DialogTitle>

         <DialogContent>

           <div>Prihlasujem do systému...</div>
           <br></br>

           <CircularProgress />

         </DialogContent>
       </Dialog>
     </div>
     )
   }

    let content = (<div>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.menoError}
                    name="meno"
                    value={this.state.meno}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="meno"
                    label="Meno"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.menoHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.priezviskoError}
                    name="priezvisko"
                    value={this.state.priezvisko}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="priezvisko"
                    label="Priezvisko"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.priezviskoHelperText}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    error = {this.state.firmaError}
                    name="firma"
                    value={this.state.firma}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="firma"
                    label="Firma"
                    type="text"
                    fullWidth
                    helperText = {this.state.firmaHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="ico"
                    error = {this.state.icoError}
                    value={this.state.ico}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="ico"
                    fullWidth
                    label="IČO"
                    type="text"
                    helperText = {this.state.icoHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="ic_dph"
                    error = {this.state.ic_dphError}
                    value={this.state.ic_dph}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="ic_dph"
                    label="DIČ/IČ DPH"
                    type="text"
                    fullWidth
                    helperText = {this.state.ic_dphHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    error = {this.state.ulicaError}
                    name="ulica"
                    value={this.state.ulica}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    required
                    margin="dense"
                    id="ulica"
                    label="Ulica"
                    type="text"
                    fullWidth
                    helperText = {this.state.ulicaHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    error = {this.state.mestoError}
                    name="mesto"
                    value={this.state.mesto}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    required
                    margin="dense"
                    id="mesto"
                    label="Mesto"
                    type="text"
                    fullWidth
                    helperText = {this.state.mestoHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    error = {this.state.pscError}
                    name="psc"
                    value={this.state.psc}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="psc"
                    label="PSČ"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.pscHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.telError}
                    name="tel"
                    value={this.state.tel}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    required
                    margin="dense"
                    id="tel"
                    label="Telefón ( 09xx xxx xxx )"
                    type="text"
                    fullWidth
                    helperText = {this.state.telHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.user_emailError}
                    name="user_email"
                    value={this.state.user_email}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    required
                    margin="dense"
                    id="user_email"
                    label="E-mail"
                    type="email"
                    fullWidth
                    helperText = {this.state.user_emailHelperText}
                  />
                </Grid>
              </Grid>
            </div>)


  if (this.state.modal){    
    return (
      <Dialog open={true} maxWidth={"lg"} aria-labelledby="form-dialog-title"
        BackdropProps={{
          classes: {
            root: classes.root
        }}}>
        <DialogContent>
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs={12} sm={6}><img src={logo} className="App-logo" alt="logo" /></Grid>
          <Grid item xs={12} sm={6}><h2 className="reg">Registrácia</h2></Grid>
        </Grid>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit} color="primary">
            Registrácia
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return(
      <DialogContent>
        {content}
      <DialogActions>
        <Button onClick={this.handleSubmit} color="primary">
          Ulož
        </Button>
      </DialogActions>
      </DialogContent>
    )
    }
  }
}

export default withStyles(useStyles)(RegistraciaInvestor)




