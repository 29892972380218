import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import QRCode from 'qrcode.react'

import logo from './../resources/Action.net.svg';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function WelcomeCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  let params = props.params;
  let url = "https://light.sherlook.sk/application?params=" + params;

  if(props.company === "datys")
  {
    return (
      <div style = {{margin: '10px 10px 20px 20px'}}>
          <Card className={classes.root} variant="outlined">
          <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Vitajte v systéme
              </Typography>
              <Typography variant="h5" component="h2">
              v tomto účte získate informácie o Vašich pôžičkách a úveroch.
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                  <span style={{fontSize: 14}}>
                    Vaše splátky zasielajte na bankový účet číslo IBAN: SK91 8330 0000 0025 0077 0975<br />
                    Ako variabilný symbol použite : xxx<br />
                    Vaša najbližšia splátka je: <br />
                    Suma splátky je:
                    </span>
              </Typography>
              
          </CardContent>

          <CardActions>
              <Button size="small" color="primary">
                  <Link target="_blank" href="mailto:info@najcredit.sk">
                  V prípade potreby píšte prosím na: info@najcredit.sk
                  </Link>
              </Button>
          </CardActions>

          </Card>
      </div>
    );
  }

  return (
    <div style = {{margin: '10px 10px 20px 20px'}}>
        <Card className={classes.root} variant="outlined">
        <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                Vitajte v systéme
            </Typography>
            <Typography variant="h5" component="h2">
                Sherlook CRM Light
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
                <span style={{fontSize: 14}}>CRM systém Sherlook efektívne a detailne riadi obchodné prípady, dokumentáciu, pohľadávky, zmluvy...</span>
            </Typography>
            <Typography variant="body2" component="p">
                <img src={logo} className="Actionnet-logo" alt="logo" />
                <br/>
                    {bull} software development | machine learning & AI<br />
                    {bull} web development | web aplikácie<br />
                    <br />

                    <QRCode value={url} />
            </Typography>
            
        </CardContent>
        <CardActions>
            <Button size="small" color="primary">
                <Link target="_blank" href="https://www.sherlook.sk">
                www.sherlook.sk
                </Link>
            </Button>
            
            <Button size="small" color="primary">
                <Link target="_blank" href="https://www.actionnet.sk">
                www.actionnet.sk
                </Link>
            </Button>

        </CardActions>
        </Card>
    </div>
  );
}