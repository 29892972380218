import * as actionTypes from './actions';
import cloneDeep from 'lodash.clonedeep'

const initialState = {
    auth: false,
    user: {},
    subject: {},
    subject_case: {},
    company: "",
    permissions: {},
    sideBarMenu: {},
    showObject: {
        object: "history",
        params: {}
    }
}

const reducer = (state = initialState, action) =>{

    if(action.type === actionTypes.SET_USER)
    {
        //console.log(action.type, action.payload);

        let userdata = cloneDeep(action.payload.user);
        let subjectdata = cloneDeep(action.payload.subject);
        let subjectCase = cloneDeep(action.payload.subject_case);

        return {
            ...state,
            user: userdata,
            subject: subjectdata,
            subject_case: subjectCase
        }
    }

    if(action.type === actionTypes.LOWER_CREDIT)
    {
        let value = action.payload.sum;

        let dlh = parseFloat(state.subject_case.dlh_aktualne);
        if(dlh < 0)
        {
            dlh = dlh + value;
        }

        let subject_case = cloneDeep(state.subject_case);
        //console.log(subject_case);
        subject_case.dlh_aktualne = dlh;

        return {
            ...state,
            subject_case: subject_case
        }
    }

    if(action.type === actionTypes.SHOW_OBJECT)
    {

    }

    return state;
};

export default reducer;