import React, { Component } from 'react';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import Typography from '@material-ui/core/Typography';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import List from '@material-ui/core/List';

const sherlookData = 
  [

  ]

// RecursiveTreeView from material-ui lab
class TreeViewBuilder extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      showControls: props.showControls
    }
    
    this.theme = createMuiTheme({
      typography: {
        body1: {
          //fontFamily: "'Open Sans', sans-serif",
          //fontWeight: 400,
          fontSize: props.fontSize,
          //color: "red"
  
        }
      }
    })
  }


  showControls = () => {
    if(this.state.showControls){
      return <>
              <AddCircleIcon
                onClick = {() => alert('Plus clicked')}
                style = {{color: 'green'}}
                fontSize = {this.props.fontSize > 30 ? 'large' : 'default'}
              />
              <RemoveCircleIcon 
                onClick = {() => alert('Minus clicked')}
                style = {{color: 'red', marginRight: '10px'}}
                fontSize = {this.props.fontSize > 30 ? 'large' : 'default'}
              />
            </>
        
    }
  }

  renderTree = (nodes) => {
    let content = [];
    nodes.map(item => {
     let label = 
        <div style={{display: 'flex', alignItems: 'center'}}>
          {this.showControls()}
          <Typography variant="body1">
              {item.name}
          </Typography>
        </div>
      content.push( 
                    <div key={item.id}>
                      <TreeItem nodeId={item.id} label={label}>
                        {item.children ? this.renderTree(item.children) : null}
                      </TreeItem>
                    </div>
                  );
      }
    )
    return content;
  }


  render () {
    return (
      <ThemeProvider theme={this.theme}>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {this.renderTree(sherlookData)}

          <div>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem onClick={()=>this.props.logoutHandler()} button>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Odhlásiť" />
              </ListItem>
            </List>

          </div>

        </TreeView>
      </ThemeProvider>
    );
  }
}

export default TreeViewBuilder;