import axios from 'axios'
import moment from 'moment'

class DB{
    static token = "";
    static company = "";
    static db_structure = "";

    data = [];
    transaction = false;

    updateJsonTypes(arr, table)
    {
        let table_structure = DB.db_structure[table];

        let result = [];
        let index = 0;
        for(let i = 0; i < arr.length; ++i)
        {
          let record = arr[i];          


          if(table_structure !== undefined)
          {
            let keys = Object.keys(record);
            for(let ii = 0; ii < keys.length; ii++)
            {
              let key = keys[ii];
              if(table_structure[key] === undefined)
              {
                console.log("updateJsonTypes - undefined key", table, key);
                continue;
              }
  
              if(table_structure[key]["type"] === "uint" || table_structure[key]["type"] === "int")
              {
                record[key] = parseInt(record[key]);
              }
              else if(table_structure[key]["type"] === "double")
              {
                record[key] = parseFloat(record[key]);
              }
              else if(table_structure[key]["type"] === "QDateTime")
              {
  
              }
              else if(table_structure[key]["type"] === "char")
              {
                if(table_structure[key]["length"] === 1)
                {
                  //boolean
                  let value = parseInt(record[key]);
                  //TODO use translation
                  if(value === 0) record[key] = "nie";
                  else record[key] = "áno";
                }
              }
              else if(table_structure[key]["type"] === "QDate")
              {
                let d = new Date(record[key]);
                record["_" + key] = record[key];//to preserve original value
                record[key] = moment(d).format('DD.MM.YYYY');
              }
  
            }
          }
          else
          {
            console.warning("no table structure provided");
          }

          record._index = index;
          result.push(record);

          index++
        }

        return arr;
    }

    dbSelect(data, exec = false, error_message = "")
    {
      //
      //let obj = {type: "select", error_message: error_message};
      //this.data.push(obj);

      //data can be string, or object SqlQueryBuilder!!!
      //TODO push

      //or sql as string
      if((typeof data) == "object")
      {
        if(data.className === "SqlQueryBuilder")
        {
          //if(error_message === undefined) error_message = "update pre tabulku " + table + " zlyhal";

          //run later
          if(exec === false)
          {
            let obj = {type: "select", className: "SqlQueryBuilder", obj: data, error_message: error_message};
            this.data.push(obj);
  
            return;
          }
        }
      }

      if((typeof data) == "string")
      {
        //alert("str");
      }

      return new Promise((resolve, reject) => {
        let obj = {
          token: DB.token,
          company: DB.company,
          type: "select",
          data: data,
          error_message: error_message
        }

        let json = JSON.stringify(obj);

        axios('/backend/db_connector.php',
        {
          method: 'POST',
          mode: 'no-cors',
          data: json,
          headers: {
            'Access-Control-Allow-Origin': '*',
            "Content-Type": "text/html;charset=UTF-8"
          },
          withCredentials: false,
          credentials: 'same-origin',
        })
        .then(response => {  
    
          if(!response.data.sucess)
          {
            throw response.data.message;
          }
    
          if(response.data.sucess)
          {
           resolve( response.data.result );
          }
  
      })
      .catch(error => {
        const message = error + "";
  
        //console.log(message);

        reject(message);
      }); 

      })

    }

    dbStartTransaction()
    {
      let obj = {type: "START TRANSACTION"};
      this.data.push(obj);
    }

    dbCommitTransaction()
    {
      let obj = {type: "COMMIT"};
      this.data.push(obj);
    }

    dbDelete(table, conditions, error_message)
    {

      if(Number.isInteger(conditions))
      {
        conditions = {"id": conditions};
      }

      if(error_message === undefined) error_message = "zlyhalo zmazanie z tabulky " + table;
      let obj = {type: "delete", table: table, conditions: conditions, error_message: error_message};
      this.data.push(obj);
    }

    dbInsert(data, table, foreign_keys, error_message) {
      //console.log(data, table, condition);

//foreign key: table: lastInsertedId
//foreign_keys: {"subject_id": {"subject": "id"}}
      if(foreign_keys === undefined) foreign_keys = {};

      //token
      if(error_message === undefined) error_message = "insert pre tabulku " + table + " zlyhal";
      let obj = {type: "insert", foreign_keys: foreign_keys, table: table, params: {...data}, error_message: error_message};
      this.data.push(obj);
    }

    dbUpdate(data, table, condition, error_message) {
        //console.log(data, table, condition);

        //token
        if(error_message === undefined) error_message = "update pre tabulku " + table + " zlyhal";
        let obj = {type: "update", table: table, params: {...data}, condition: condition, error_message: error_message};
        this.data.push(obj);
    }

    exec()
    {
      //build json and send to server

      return new Promise((resolve, reject) => {

          let obj = {
              token: DB.token,
              company: DB.company,
              data: this.data
          }

          let json = JSON.stringify(obj);

          axios('/backend/db_connector.php',
          {
            method: 'POST',
            mode: 'no-cors',
            data: json,
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "text/html;charset=UTF-8"
            },
            withCredentials: false,
            credentials: 'same-origin',
          })
          .then(response => {  
      
            //console.log(response);

            if(!response.data.sucess)
            {
              //alert(response.data.error_message);
              console.log(response);

              let errors = [];
              if("data" in response)
              {
                if(Array.isArray(response.data.result))
                {
                  for(let i = 0; i < response.data.result.length; ++i)
                  {
                    if(!response.data.result[i].sucess) errors.push(response.data.result[i].error_message);
                  }
                }
              }

              throw errors;
            }
      
            if(response.data.sucess)
            {
              resolve(response.data.result);
            }
    
        })
        .catch(error => {
          const message = error + "";
    
          console.log("catch", message, error);

          reject(message);
        }); 

      })
    }
}

export default DB;