import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';

import AlertDialog from './../../AlertDialog/AlertDialog';
import Config from './../../config/config'
import DB from './../../../util/db/db';

// card core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';

import moment from 'moment'

import LoansForm from './form/loans'
import CasePayments from './../Cases/CasesPayments';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (
        <Box p={3}>
          {children}
        </Box>
        )}
    </div>
);

}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto(props) {

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  //data collector
  const [data, setData] = React.useState(props.data);
  const [changedKeys, setChangedKeys] = React.useState([]);

  const [showAlert, setShowAlert] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");

  const getValue = (key) =>
  {
    let value = "";
    if(data !== undefined) value = data[key];
    if(value === undefined) value = "";

    return value;
  } 

const showAlertDialog = (title, message) =>
{
  setShowAlert(true);
  setAlertTitle(title);
  setAlertContent(message);
}

const hideAlertDialog = () =>
{
  setShowAlert(false);
}

  const collectData = (table, newdata) =>
  {
    //console.log("collectData", table, newdata);
    if(table === "subject")
    {
      let k = changedKeys.concat(Object.keys(newdata));
      let uniqueArray = [...new Set(k)];

      const d = {...data, ...newdata};

      setData(d);
      setChangedKeys(uniqueArray);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const save = () => {

    return;

    
  };

return (
  <>
  <Card>
    <CardHeader color="success">
        
            <Tooltip title="návrat na hlavnú stránku" arrow>
                <Button
                onClick = {(event) =>props.handleBack()}
                startIcon={<ArrowBackIcon />}
                >
                {props.status === "EDIT" ? "Späť" : "vytvorenie"}
                </Button>
            </Tooltip>
    


    </CardHeader>
    <CardBody>
    <div className={classes.root}>
      <AppBar position="static" color="default">

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Základné údaje" {...a11yProps(0)} />
          <Tab label="Prijaté platby" {...a11yProps(1)} />
        </Tabs>
        
      </AppBar>
      <TabPanel value={value} index={0}>
        <LoansForm collectData = {collectData} table = "case" data = {data}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <CasePayments case_id = {props.data.id}/>
      </TabPanel>

    </div>
    </CardBody>
  </Card>

  <AlertDialog
  open = {showAlert}
  title = {alertTitle}
  content = {alertContent}
  onConfirm ={hideAlertDialog}
  alertType = {alertType}
  />
  </>
)

}