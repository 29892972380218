import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'
import './sherlook.css';

import logoSherlook from './../resources/Sherlook.svg'
import logoNajcredit from './../resources/najcredit.svg';

import findGetParameterInUrl from '../../util/findGetParameterInUrl'
import { Base64 } from 'js-base64';

import MainMenu from './mainMenu/mainMenu'
import Config from '../config/config'
import UserProfile from './userprofile/userprofile'

import Sidebar from "react-sidebar";

import TreeViewBuilder from './treeViewBuilder/treeViewBuilder';
import Subject from './subject/subject';
import Users from './users/users';
import DyhyPalluxOrders from './dyhypallux/dyhypalluxorders';
import Loans from './najcredit/loans';

import WelcomeCard from './welcome';

import {connect} from 'react-redux'

class Sherlook extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            "auth": props.auth,
            company: props.company,
            showObject: "",
            objectParams: null,
            sidebarOpen: false
        }

        this.logoutHandler = props.logoutHandler
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        
    }

    logout()
    {}

    onSetSidebarOpen(open) {
        this.setState(
            {
              ...this.state,
              sidebarOpen: open
            }
        )
    }

    buildSideBar()
    {

      if(this.state.company === "datys")
      {
        return (
          <div style={{padding: '10px'}}>
              <img src={logoNajcredit} height = {90} className="App-logo" alt="logo" />
              <TreeViewBuilder showObject={(params) => this.showObject(params)} showControls={true} logoutHandler={() => this.logoutHandler()} fontSize={20}/>
          </div>
          );
      }
      //let logo = <img src={logoSherlook} className="App-logo" alt="logo" />
      //if(company === "datys") logo = <img src={logoNajcredit} height = {90} className="App-logo" alt="logo" />

        return (
        <div style={{padding: '10px'}}>
            <img src={logoSherlook} className="App-logo" alt="logo" />
            <TreeViewBuilder showObject={(params) => this.showObject(params)} showControls={true} logoutHandler={() => this.logoutHandler()} fontSize={20}/>
        </div>
        );

    }

    showSideBar()
    {
        this.onSetSidebarOpen(true);
    }

    showObject(objectName)
    {
        this.setState(
            {
              ...this.state,
              showObject: objectName
            }
          )
    }

    render() { 

        let redirectToLogout = false;

        if(this.state.showObject === "LogOut") redirectToLogout = true;
        if(!this.state.auth) redirectToLogout = true;

        if(redirectToLogout)
        {   
            let param = "/login";
            if(this.state.company !== "") param = "/login?company=" + this.state.company;

            let loginparams = findGetParameterInUrl("params");
            if(loginparams != null)
            {
              param = "/login?params=" + loginparams;
            }

            return <Redirect to={param}  />
        }

        //console.log("props", this.props);

        let username = this.props.user_name;
        let password = this.props.user_password;
        let company = this.props.company;

        let o = {username: username, password: password, redirect: true, company: company};
        let s = Base64.encode(JSON.stringify(o));

        let obj = (
          <WelcomeCard
          params = {s}
          company={company}
          />
          );

        //if(this.state.showObject === "UserProfile") obj = <UserProfile user={this.props.user} subject={this.props.subject}/>;
        //if(this.state.showObject === "Subject") obj = <Subject />;
        //if(this.state.showObject === "Users") obj = <Users />;
        //if(this.state.showObject === "DyhyPalluxOrders") obj = <DyhyPalluxOrders/>;
        if(this.state.showObject === "showLoans") obj = <Loans typ = {Config.subject.typ}/>;
        

        let logo = <img src={logoSherlook} className="App-logo" alt="logo" />
        if(company === "datys") logo = <img src={logoNajcredit} height = {90} className="App-logo" alt="logo" />

        return (
            <>
              <Sidebar
                  sidebar={this.buildSideBar()}
                  open={this.state.sidebarOpen}
                  onSetOpen={this.onSetSidebarOpen}
                  styles={{ sidebar: { background: "white", zIndex: 50000, } }}
              >
                  {logo}

                  <MainMenu
                      onSetSidebarOpen={(open) => this.onSetSidebarOpen(open)}
                      showObject={(params) => this.showObject(params)}
                      logoutHandler={() => this.logoutHandler()}
                      subject_case = {this.props.subject_case}
                      company={company}
                  />
                  {obj}
              </Sidebar>
                
            </>
          );
    }
}

const mapStateToProps = state => {
  console.log("sherlook", state);
    return{
      user: state.user,
      subject: state.subject,
      subject_case: state.subject_case,
      permissions: state.permissions,
      showObject: state.showObject
    }
  };
  
  //you can call: this.props.someFunctionName
  
  /*
  const mapDispatchToProps = dispatch => {
    return {
      setUser: (params) => dispatch ({
        type: 'SET_USER',
        payload: {
          user: params.user,
          subject: params.subject
        }
      })
    }
  }
  */

export default connect(mapStateToProps, null)(Sherlook);