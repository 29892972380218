import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import logo from './../resources/Sherlook.svg';
import Grid from '@material-ui/core/Grid';
import icoValidator from '../../util/icoValidator';




const initialState = {
    meno: "",
    priezvisko: "",
    firma: "",
    ico: "",
    ic_dph: "",
    ulica: "",
    mesto: "",
    psc: "",
    user_email: "",
    tel: "",
    user_name: "",
    user_password: ""
};

class Registracia extends Component {
  
  constructor(props){
    super(props)

    this.submitHandler = props.submitHandler

    this.state = {
      ...initialState,
      modal: props.modal,
    }

    let keys = Object.keys(initialState);
    for (var index in keys) {
        let key = keys[index];
        this.state[key + "Error"] = false;
        this.state[key + "HelperText"] = "";
    }

    if(props.data !== undefined)
    {
      let keys = Object.keys(initialState);

      for (var index in keys) {
        let key = keys[index];
        if (props.data[key] === undefined) continue;

        this.state[key] = props.data[key];
      }
 
    }
  }
 
  validate = () => {

    let errors = {};

    if (!this.state.meno){
      errors["meno"] = "Nezadané meno";
    }
    
    if (!this.state.priezvisko){
      errors["priezvisko"] = "Nezadané priezvisko";
    }

    if (!this.state.ulica){
      errors["ulica"] = "Nezadaná ulica";
    }
    
    if (!this.state.mesto){
      errors["mesto"] = "Nezadané mesto";
    }

    if (!this.state.psc){
      errors["psc"] = "Nezadané PSČ";
    } else if (this.validatePSC(this.state.psc)===false) {
      errors["psc"] = "Neplatný formát psč";
    }
    
    if (!this.state.tel){
      errors["tel"] = "Nezadaný telefón";
    } else if (this.validateTel(this.state.tel)===false) {
      errors["tel"] = "Nesprávny formát čísla";
    }

    if (!this.state.user_email){
      errors["user_email"] = "Email nie je zadaný";
    } else if (this.validateEmail(this.state.user_email)===false){
      errors["user_email"] = "Email je v zlom formáte";
    }

    if (!this.state.user_name){
      errors["user_name"] = "Nezadané prihlasovacie meno";
    }

    if (!this.state.user_password){
      errors["user_password"] = "Nezadané heslo";
    }

    if (this.state.firma){
      if (icoValidator.validIco(this.state.ico)===false){
        errors["ico"] = "Nesprávny formát IČO";
      }
      if (this.validateteDic(this.state.ic_dph)===false){
        errors["ic_dph"] = "Nesprávny formát DIČ";
      }
    }
    
    if (!this.state.firma){
      if (icoValidator.validIco(this.state.ico)===true){    
        errors["firma"] = "Nezadaná firma";
      } else if (this.state.ico !== "" ){
        errors["ico"] = "Nesprávny formát IČO";
      } else if (this.validateteDic(this.state.ic_dph)===true){    
        errors["firma"] = "Nezadaná firma";
      } else if (this.state.ic_dph !== "" ){
        errors["ic_dph"] = "Nesprávny formát DIČ";
      }
    }

    let keys = Object.keys(errors);
    if(keys.length > 0)
    {
      let newState = {...this.state};

      for (var index in keys) {
        let key = keys[index];
        newState[key + "Error"] = true;
        newState[key + "HelperText"] = errors[key];
      }

      this.setState(newState);

      return false;
    }

    {
      /*
      this.setState((prevState, props) => ({
        ...prevState,
      }));
      */

      //TODO prevState?
     let keys = Object.keys(initialState);

     let newState = {...this.prevState};

     for (var index in keys) {
       let key = keys[index];
       newState[key + "Error"] = false;
       newState[key + "HelperText"] = "";
     }

     this.setState(newState);

    }

    return true;

  }

  validateTel = tel => {
    const reTelefon = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    let isValid = reTelefon.test(tel);
    return isValid;
  }
  
  validatePSC = psc => {
    const rePSC = /^\d{5}$/;
    return rePSC.test(psc);
  }
  
  validateteDic = dic => {
    const reDic = /^[A-Z]{2}\d{10}$/;
    return reDic.test(dic);
  }

  validateEmail = email => {
    const reEmail = /\S+@\S+\.\S+/;
    return reEmail.test(email);
  }
    
  handleSubmit = event => {
    event.preventDefault();
    let isValid = this.validate();
    //let isValid = true;//for testing
    
    if (isValid) {

      console.log('everything ok');

      let data = {};
      let keys = Object.keys(initialState);
      for (var index in keys) {
        let key = keys[index];
        data[key] = this.state[key];
      }

      if(this.submitHandler) 
      {
        this.submitHandler(data);
      }
      else
      {
        //ajax call to register
        //post data
      }
    }
    else
    {
      //alert("chyba");
      console.log("error handleSubmit");
    }
  };

  handleChange = event => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  };

  render(){

    const classes = {
      root: {
        flexGrow: 1,
      },
      bcg: {
        backgroundColor: "transparent",
        backgroundImage: "linear-gradient(135deg, #1B8AF1, #d8deeb)"    //backgroundImage: "linear-gradient(to right, #1B8AF1, #d8deeb)"
      },
    }
    
    let content = (<div className={classes.root}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.menoError}
                    name="meno"
                    value={this.state.meno}
                    onChange={this.handleChange}
                    margin="dense"
                    id="meno"
                    label="Meno"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.menoHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.priezviskoError}
                    name="priezvisko"
                    value={this.state.priezvisko}
                    onChange={this.handleChange}
                    margin="dense"
                    id="priezvisko"
                    label="Priezvisko"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.priezviskoHelperText}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    error = {this.state.firmaError}
                    name="firma"
                    value={this.state.firma}
                    onChange={this.handleChange}
                    margin="dense"
                    id="firma"
                    label="Firma"
                    type="text"
                    fullWidth
                    helperText = {this.state.firmaHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="ico"
                    error = {this.state.icoError}
                    value={this.state.ico}
                    onChange={this.handleChange}
                    margin="dense"
                    id="ico"
                    fullWidth
                    label="IČO"
                    type="text"
                    helperText = {this.state.icoHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="ic_dph"
                    error = {this.state.ic_dphError}
                    value={this.state.ic_dph}
                    onChange={this.handleChange}
                    margin="dense"
                    id="ic_dph"
                    label="DIČ/IČ DPH"
                    type="text"
                    fullWidth
                    helperText = {this.state.ic_dphHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    error = {this.state.ulicaError}
                    name="ulica"
                    value={this.state.ulica}
                    onChange={this.handleChange}
                    required
                    margin="dense"
                    id="ulica"
                    label="Ulica"
                    type="text"
                    fullWidth
                    helperText = {this.state.ulicaHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    error = {this.state.mestoError}
                    name="mesto"
                    value={this.state.mesto}
                    onChange={this.handleChange}
                    required
                    margin="dense"
                    id="mesto"
                    label="Mesto"
                    type="text"
                    fullWidth
                    helperText = {this.state.mestoHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    error = {this.state.pscError}
                    name="psc"
                    value={this.state.psc}
                    onChange={this.handleChange}
                    margin="dense"
                    id="psc"
                    label="PSČ"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.pscHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.telError}
                    name="tel"
                    value={this.state.tel}
                    onChange={this.handleChange}
                    required
                    margin="dense"
                    id="tel"
                    label="Telefón ( 09xx xxx xxx )"
                    type="text"
                    fullWidth
                    helperText = {this.state.telHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.user_emailError}
                    name="user_email"
                    value={this.state.user_email}
                    onChange={this.handleChange}
                    required
                    margin="dense"
                    id="user_email"
                    label="E-mail"
                    type="email"
                    fullWidth
                    helperText = {this.state.user_emailHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.user_nameError}
                    name="user_name"
                    value={this.state.user_name}
                    onChange={this.handleChange}
                    required
                    margin="dense"
                    id="user_name"
                    label="Prihlasovacie meno"
                    type="text"
                    fullWidth
                    helperText = {this.state.user_nameHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.user_passwordError}
                    name="user_password"
                    value={this.state.user_password}
                    onChange={this.handleChange}
                    required
                    margin="dense"
                    id="user_password"
                    label="Heslo"
                    type="password"
                    autoComplete="current-password"
                    fullWidth
                    helperText = {this.state.user_passwordHelperText}
                  />
                </Grid>
              </Grid>
            </div>)


  if (this.state.modal){    
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title"
        BackdropProps={{
          classes: {
            root: classes.bcg
        }}}>
        <DialogContent>
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs={12} sm={6}><img src={logo} className="App-logo" alt="logo" /></Grid>
          <Grid item xs={12} sm={6}><h2 className="reg">Registrácia</h2></Grid>
        </Grid>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit} color="primary">
            Registrácia
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return(
      <DialogContent>
        {content}
      <DialogActions>
        <Button onClick={this.handleSubmit} color="primary">
          Ulož
        </Button>
      </DialogActions>
      </DialogContent>
    )
    }
  }
}

export default Registracia;




